import { alpha, getContrastRatio } from '@mui/material';

export const palette = {
  common: {
    white: '#FFF',
    black: '#000',
  },
  primary: {
    main: '#273B48',
    light: alpha('#273B48', 0.5),
    dark: '#344B5A',
    contrastText: getContrastRatio('#273B48', '#FFF') > 4.5 ? '#FFF' : '#111',
  },
  secondary: {
    main: '#E21A1A',
    light: alpha('#E21A1A', 0.5),
    dark: '#F14848',
    contrastText: getContrastRatio('#E21A1A', '#FFF') > 4.5 ? '#FFF' : '#111',
  },
  text: {
    primary: '#1E1E1E',
    secondary: '#606060',
    light: alpha('#FFF', 0.5),
    dark: alpha('#111', 0.5),
  },
  background: {
    default: '#F6F6F6',
    paper: '#FFF',
  },
  border: {
    stroke: '#BBC0CB',
    divider: '#EBECEF',
  },
  customs: {
    train: '#5A5A5A',
    trainPlanned: '#999999',
    trainNight: '#8AB0D2',
    trainDay: '#A3A86B',
    trainLocal: '#DDAB71',
    trainSpeedLocal: '#90CC7C',
    trainHighSpeed: '#7E67DD',
    trainPlannedHighSpeed: '#B09DFC',
    auto: '#FF792E',
    autoV2: '#83CA6B',
    trolleybus: '#3362BC',
    metro: '#A03FDB',
    waterTransport: '#00A1DC',
    otherTransport: '#D08731',
    autoPlanned: '#FFB68D',
    expressways: '#DB3F3F',
    expresswaysPlanned: '#F68C8C',
    avia: '#00A1DC',
    aviaPlanned: '#58B0E2',
    bus: '#F3A015',
    busPlanned: '#FFCE7C',
    transportAreaInspectorBoundaries: '#50A583',
    link: '#3679EA',
    bgHover: '#E9EEF3',
    bgError: '#CE0C3C',
    agTransportDistricts: '#77b1d0',
  },
  success: {
    main: '#7CBC68',
  },
  error: {
    main: '#FB4518',
  },
};
