import { Box, CircularProgress, SxProps, Theme, alpha } from '@mui/material';

type LoadingType = {
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  circularColor?: string;
  isBackgroundColor?: boolean;
};

export const Loading = (props: LoadingType) => {
  const { isLoading = false, sx, circularColor, isBackgroundColor = true } = props;

  return (
    isLoading && (
      <Box
        sx={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme =>
            isBackgroundColor ? alpha(theme.palette.common.black, 0.3) : 'transparent',
          ...sx,
        }}
      >
        <CircularProgress
          sx={{
            color: theme => circularColor || theme.palette.secondary.main,
          }}
        />
      </Box>
    )
  );
};
