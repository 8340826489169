import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowLongRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={21}
      height={8}
      viewBox="0 0 21 8"
      fill="none"
      {...props}
    >
      <path
        d="M0.513396 4.52684H19.248L16.7427 7.09779C16.5424 7.30326 16.5424 7.64043 16.7427 7.8459C16.9429 8.05137 17.2663 8.05137 17.4665 7.8459L20.8498 4.37405C21.0501 4.16859 21.0501 3.83668 20.8498 3.63122L17.4717 0.154099C17.2715 -0.0513665 16.948 -0.0513665 16.7478 0.154099C16.5476 0.359565 16.5476 0.691472 16.7478 0.896938L19.248 3.47316H0.513396C0.231028 3.47316 0 3.71024 0 4C0 4.28976 0.231028 4.52684 0.513396 4.52684Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
