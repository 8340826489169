import { ApiProvider } from '@api/client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { SnackbarProvider } from 'notistack';
import { useCallback } from 'react';
import { RouterProvider } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { Loading } from '@components/Loading/Loading';

import { keycloak } from '@system/keycloak/keycloak';
import { router } from '@system/routing';
import { Theme } from '@system/theme';
import '@system/theme/font.css';
import { palette } from '@system/theme/palette';

import { NavigationBlockProvider } from '@utils/NavigationBlockContext/NavigationBlockContext';

import { UpdateProfileCurrentUser } from '@stores/currentUser/store';
import '@stores/index';

function App() {
  const onEventKeycloak = useCallback(
    (e: string) =>
      (e === 'onAuthSuccess' || e === 'onAuthRefreshSuccess' || e === 'onAuthLogout') &&
      UpdateProfileCurrentUser(),
    [],
  );

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={
        <Loading
          isLoading={true}
          sx={{ backgroundColor: '#FFF' }}
          circularColor={palette.secondary.main}
        />
      }
      onEvent={onEventKeycloak}
      autoRefreshToken
      initOptions={{
        checkLoginIframe: true,
        enableLogging: true,
      }}
    >
      <NavigationBlockProvider>
        <ThemeProvider theme={Theme}>
          <SnackbarProvider />
          <CssBaseline />
          <ApiProvider>
            <RouterProvider router={router} />
          </ApiProvider>
        </ThemeProvider>
      </NavigationBlockProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
