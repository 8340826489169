import Academy from '@assets/Academy.png';

import { Box, Typography } from '@mui/material';

import { ArrowLongRightIcon } from '@components/icons';

export const AcademicBanner = () => {
  return (
    <a
      href={location.origin + '/src/assets/files/Академия.pdf'}
      download
    >
      <Box
        sx={{
          padding: theme => theme.spacing(0.5, 1),
          border: '1px solid transparent',
          backgroundImage: theme =>
            `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(92.49deg, #0067A2 8.02%, #14A0DD 97.36%)`,
          borderRadius: theme => theme.shape.borderRadius / 2,
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
          display: 'flex',
          alignItems: 'center',
          columnGap: 1,
          maxWidth: '21ch',
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{ backgroundImage: `url(${Academy})`, minWidth: '32px', height: '22px', fontSize: '32px' }}
        />

        <Typography
          sx={{
            fontSize: '0.75rem',
            color: theme => theme.palette.common.white,
          }}
        >
          Академия транспортного моделирования
          <ArrowLongRightIcon
            sx={{
              height: 'auto',
              width: 'auto',
              ml: 1,
            }}
          />
        </Typography>
      </Box>
    </a>
  );
};
