import { createApi, createEvent, createStore } from 'effector';

const initState: CurrentUserStoreType = {
  firstName: '',
  lastName: '',
};

export const $CurrentUserStore = createStore<CurrentUserStoreType>(initState);

export const CurrentUserStoreApi = createApi($CurrentUserStore, {
  getProfileCurrentUser: (store, payload: CurrentUserStoreType) => ({
    ...store,
    ...payload,
  }),
});

export const UpdateProfileCurrentUser = createEvent();

export type CurrentUserStoreType = {
  firstName: string;
  lastName: string;
};
