import { sample } from 'effector';

import { CurrentUserStoreApi, UpdateProfileCurrentUser } from './store';

import { keycloak } from '@system/keycloak/keycloak';

// Обновление профиля текущего юзера
sample({
  clock: UpdateProfileCurrentUser,
  fn: () => ({
    firstName: keycloak.tokenParsed?.given_name || '',
    lastName: keycloak.tokenParsed?.family_name || '',
  }),
  target: CurrentUserStoreApi.getProfileCurrentUser,
});
